import i18n from "i18next";

const resources = {
  en: {
    "HeroBackground.text": "Misogi",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

export { HeroBackground as default } from "./HeroBackground";
