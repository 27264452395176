import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const resources = {
  en: {
    "HeroContactButton.text": "misogi labs",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

function HeroContactButton() {
  const { t } = useTranslation();

  return (
    <Link
      className="primary-button w-inline-block elem93 bg-[color:var(--globals--color--surfaces--01)] box-border max-w-[100%] flex text-[color:var(--core--colors--neutral--800)] no-underline transition-transform duration-[0.3s] ease-[ease] delay-0 pt-[calc(var(--globals--paddings--pd-default)*0.8)] pb-[calc(var(--globals--paddings--pd-default)*0.8)] gap-x-[var(--globals--gaps--gap-extra-small)] gap-y-[var(--globals--gaps--gap-extra-small)] border-[length:var(--globals--border-width--bw-default)] border-[color:var(--core--colors--neutral--100)] rounded-[var(--components--buttons--border-radius--br-pill)] text-[length:14px] text-center justify-center items-center font-semibold leading-[1em] px-[12px] border-solid hover:text-[color:var(--core--colors--neutral--800)] hover:scale-[0.94] mobile:pt-[calc(var(--globals--paddings--pd-default)*0.8)] mobile:pb-[calc(var(--globals--paddings--pd-default)*0.8)] mobile:px-[12px] mobile:text-[length:14px] mobile:w-auto"
      to="https://www.linkedin.com/company/misogilabs/"
      target="_blank"
      rel="noopener noreferrer"
      id="w-node-e9da5fd6-ab44-5b2f-81ad-6e5f36bfab9c-36bfab9c"
    >
      <div className="elem334 box-border">{t("HeroContactButton.text")}</div>
    </Link>
  );
}

export default HeroContactButton;
