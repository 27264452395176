function HeroBackgroundVideoWrapper() {
  return (
    <div className="hero-image---v1-video-bg w-background-video w-background-video-atom elem341 box-border text-white h-[500px] absolute overflow-hidden z-auto left-[0%] right-[0%] bottom-[1%]">
      <img
        src="https://createsiteai.b-cdn.net/092a814e-eafb-4e8a-9be7-b85ca6751049"
        alt="Background"
        className="w-full h-full object-cover filter brightness-75 contrast-125"
      />
    </div>
  );
}

export default HeroBackgroundVideoWrapper;
