import HeroBackground from "./HeroBackground";
import HeroContent from "./HeroContent";

function HeroSection() {
  return (
    <section className="section hero-section---v1 elem1 flex box-border bg-[color:#040404] z-[1] flex-col justify-center items-center relative overflow-hidden min-h-screen pt-[250px] pb-[332px] tablet:py-[20%] mobile:py-[15%] mobile:pt-[calc(var(--components--sections--paddings--pd-medium)*0.7)] mobile:pb-[calc(var(--components--sections--paddings--pd-medium)+100px)]">
      <div className="w-layout-blockcontainer container-default z-index-1 width-100 w-container elem324 box-border max-w-[1298px] block w-[100%] z-[1] relative mx-auto px-[24px] before:content-['_'] before:table after:content-['_'] after:table after:clear-both tablet:max-w-[728px] mobile:max-w-[none] mobile:pr-[var(--core--container-default--padding--mb)] mobile:pl-[var(--core--container-default--padding--mb)]">
        <HeroContent />
      </div>
      <HeroBackground />
    </section>
  );
}

export default HeroSection;
