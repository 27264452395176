import FooterBottomContent from "./FooterBottomContent";
import FooterDivider from "./FooterDivider";
import FooterLogo from "./FooterLogo";

function FooterBottom() {
  return (
    <div
      className="footer-center v1 elem1486 box-border"
      data-w-id="ed13709d-5a1f-d53b-9d1b-c811727ed3fa"
    >
      <div className="w-layout-grid grid-footer-v1 elem1487 box-border gap-y-[32px] gap-x-[48px] grid-rows-[auto] grid-cols-[1fr] auto-cols-[1fr] grid tablet:gap-y-[60px] tablet:grid-cols-[1fr] tablet:justify-between mobile:justify-between mobile:gap-x-[var(--globals--grid-gap--medium)] mobile:gap-y-[48px] mobile:grid-cols-[1fr]">
        
        
        <FooterBottomContent/>
      </div>
    </div>
  );
}

export default FooterBottom;