import { Link } from "react-router-dom";
import { FaLinkedin, FaLinkedinIn } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";

function FooterSocialIcons() {
  return (
    <div className="social-media-grid elem1494 box-border gap-x-[16px] items-center flex justify-end h-full">
      <Link
        className="social-media-icon-wrapper-dark w-inline-block elem133 bg-[color:#0000] box-border max-w-[100%] flex text-[color:var(--core--colors--neutral--100)] no-underline transition-opacity duration-[0.3s] ease-[ease] delay-0 justify-center items-center w-[20px] h-[20px] text-[length:20px] hover:text-[color:var(--core--colors--neutral--400)] hover:opacity-70"
        to="https://www.linkedin.com/company/misogilabs/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="flex space-x-4">
        
        <FaLinkedinIn className="elem1495 box-border text-2xl" />
        
        
      </div>
      </Link>
    </div>
  );
}

export default FooterSocialIcons;