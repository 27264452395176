import i18n from "i18next";
import { useTranslation } from "react-i18next";

const resources = {
  en: {
    "Header.logoAlt": "Misogi Labs Logo",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

function Header() {
  const { t } = useTranslation();

  return (
    <div
      className="header-wrapper w-nav elem231 box-border z-[1000] bg-[color:#040404] static w-[100%] py-[20px] before:content-['_'] before:table after:content-['_'] after:table after:clear-both"
      data-animation="over-left"
      data-collapse="all"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
    >
      <div className="container-default w-container elem232 box-border max-w-[1298px] mx-auto px-[24px] before:content-['_'] before:table after:content-['_'] after:table after:clear-both tablet:max-w-[728px] mobile:max-w-[none] mobile:pr-[var(--core--container-default--padding--mb)] mobile:pl-[var(--core--container-default--padding--mb)]">
        <div
          className="header-container-wrapper elem233 box-border gap-x-[16px] justify-between items-center flex mobile:gap-x-[var(--globals--gaps--gap-small)]"
          data-w-id="f5df0507-e534-97d9-4d61-d34fb77a34ef"
        >
          <img
            src="https://createsiteai.b-cdn.net/c/_/6972a597-911c-458f-a359-f74e9fa90cea"
            alt={t("Header.logoAlt")}
            className="w-20 mobile:w-14 tablet:w-16 laptop:w-18 desktop:w-20 mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
