import React from "react";
import { useTranslation } from "react-i18next";

interface BackgroundTextProps {
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
}

export const BackgroundText: React.FC<BackgroundTextProps> = React.forwardRef<
  HTMLDivElement,
  BackgroundTextProps
>(({ className }, ref) => {
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      className={`hero-bg-text---v1 box-border z-[2] opacity-10 text-[#383838] text-center uppercase w-[auto] max-w-[100%] text-[length:200px] font-extrabold leading-[1em] relative tablet:w-[100%] mobile:w-[100%] tablet:text-[length:22.5dvw] mobile:text-[length:22.5dvw] laptop:text-[length:22.5dvw] desktop:text-[length:15dvw] ${className}`}
    >
      {t("HeroBackground.text")}
    </div>
  );
});

BackgroundText.displayName = "BackgroundText";
