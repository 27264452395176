import FooterBottom from "./FooterBottom";

function Footer() {
  return (
    <footer
      className="footer-wrapper elem0 block box-border bg-[color:#040404] overflow-hidden desktop:self-stretch py-8 mt-[100px]"
      data-w-id="ed13709d-5a1f-d53b-9d1b-c811727ed3a4"
    >
      <div className="w-layout-blockcontainer container-default w-container elem1307 box-border max-w-[1298px] block mx-auto px-[24px] before:content-['_'] before:table after:content-['_'] after:table after:clear-both tablet:max-w-[728px] mobile:max-w-[none] mobile:px-[32px] tablet:px-[32px]">
        <FooterBottom />
      </div>
    </footer>
  );
}

export default Footer;