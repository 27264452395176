import { Link } from "react-router-dom";
import HeroContactButton from "./HeroContactButton";

function HeroButtons() {
  return (
    <div className="mg-top-medium elem332 box-border mt-[32px] mobile:mt-[var(--globals--margins--mg-default)]">
      <div className="buttons-row elem333 box-border gap-x-[0px] justify-center items-center flex mobile:gap-x-[var(--globals--gaps--gap-medium)] mobile:gap-y-[var(--globals--gaps--gap-medium)] mobile:flex-col mobile:items-stretch mobile:w-[100%]">
        <HeroContactButton/>
        
      </div>
    </div>
  );
}

export default HeroButtons;