import { gsap } from "gsap";
import { useEffect } from "react";

export function useDataWIdAnimation150() {
  useEffect(() => {
    gsap.set('[data-w-id="0b73a0a5-25b6-8f29-e252-aff47194ee2e"]', {"opacity": 0});
    const tl = gsap.timeline({"scrollTrigger": {"trigger": "[data-w-id=\"0b73a0a5-25b6-8f29-e252-aff47194ee2e\"]", "start": "top bottom", "end": "bottom top", "toggleActions": "play none none none", "once": true}});
    tl.addLabel("label0");
    tl.to('[data-w-id="0b73a0a5-25b6-8f29-e252-aff47194ee2e"]', {"duration": 0.5, "delay": 0.6, "ease": "power1.inOut", "opacity": 1}, "label0");
  }, []);
}