import i18n from "i18next";
import { useTranslation } from "react-i18next";
import HeroButtons from "./HeroButtons";

const resources = {
  en: {
    "HeroContent.description":
      "Get to the best clinical candidate faster by cutting ADME related failures with\npredictive physics-based AI",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

function HeroContent() {
  const { t } = useTranslation();

  return (
    <div
      className="text-center elem325 box-border text-center mt-32 mobile:mt-16 tablet:mt-28 laptop:mt-30"
      data-w-id="cd60d18e-b4b8-7c71-843f-64311e813cfc"
      style={{
        WebkitTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        msTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        opacity: 0,
      }}
    >
      <div className="elem327 box-border">
        <div className="inner-container _715px center elem328 box-border max-w-[715px] mx-auto">
          <div className="text-light elem329 box-border text-[color:var(--core--colors--neutral--300)]">
            <p className="elem330 box-border my-[0] whitespace-pre-line">
              {t("HeroContent.description")}
            </p>
          </div>
        </div>
      </div>
      <HeroButtons />
    </div>
  );
}

export default HeroContent;
