import { useAnimations } from "../hooks/useAnimations";
import MoreTemplatesBadge from "../components/common/MoreTemplatesBadge";
import Footer from "../components/layout/Footer/Footer";
import Header from "../components/layout/Header/Header";
import HeroSection from "../components/sections/Hero/HeroSection";

function Home() {
  useAnimations();

  return (
    <>
      <div className="page-wrapper elem230 box-border relative min-h-screen flex flex-col">
        <Header/>
        <HeroSection/>
        <Footer/>
      </div>
      
    </>
  );
}

export default Home;