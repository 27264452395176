import React from "react";
import { useBackgroundTextAnimation } from "./useBackgroundTextAnimation";
import { BackgroundText } from "./BackgroundText";
import { BackgroundGradient } from "./BackgroundGradient";
import HeroBackgroundVideoWrapper from "../HeroBackgroundVideoWrapper";
import { useTranslation } from "react-i18next";

export const HeroBackground: React.FC = () => {
  const { t } = useTranslation();
  const backgroundTextRef = useBackgroundTextAnimation();

  return (
    <div
      className="image-wrapper hero-bg-image---v1 elem336 box-border overflow-hidden z-[-1] w-[100%] min-w-[1328px] max-w-[1328px] absolute mt-[-226px] inset-y-auto tablet:min-w-[auto] mobile:min-w-[auto] tablet:max-w-[100%] mobile:max-w-[100%] tablet:mt-[-275px] mobile:mt-[-297px] laptop:mt-[-351px] desktop:mt-[-351px]"
      data-w-id="0b73a0a5-25b6-8f29-e252-aff47194ee2e"
      style={{ opacity: 1 }}
    >
      <BackgroundText ref={backgroundTextRef} className="elem337" />
      <div className="hero-bg-text---v1---02---wrapper elem338 box-border justify-between items-center mt-[-5%] mb-[-5%] flex relative overflow-hidden">
        <div className="hero-image---v1-video-bg---shadow elem339 box-border z-[2] bg-[color:#04040499] absolute inset-[0%]" />
        <div className="hero-bg-text---v1 _02 elem340 box-border z-[1] opacity-100 text-white text-center uppercase w-full max-w-[100%] text-[length:200px] font-extrabold leading-[1em] relative bg-[color:#040404] mix-blend-darken tablet:w-[100%] mobile:w-[100%] tablet:text-[length:22.5dvw] mobile:text-[length:22.5dvw] laptop:text-[length:22.5dvw] desktop:text-[length:15dvw] mx-auto">
          {t("HeroBackground.text")}
        </div>
        <HeroBackgroundVideoWrapper />
      </div>
      <BackgroundText className="elem344" />
      <BackgroundGradient />
    </div>
  );
};
