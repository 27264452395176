import FooterSocialIcons from "./FooterSocialIcons";

function FooterBottomContent() {
  const currentYear = new Date().getFullYear();

  return (
    <div
      className="footer-v1-content-wrapper elem1493 box-border gap-x-[16px] gap-y-[16px] flex flex-row justify-between items-center w-full"
      id="w-node-ed13709d-5a1f-d53b-9d1b-c811727ed404-727ed3a4"
    >
      <FooterSocialIcons />
      <div className="elem1499 box-border text-sm flex items-center">
        <div className="text-neutral-light elem1500 box-border text-[color:var(--core--colors--neutral--400)]">
          <p className="elem1501 box-border my-[0]">
            © {currentYear} misogi labs
          </p>
        </div>
      </div>
    </div>
  );
}

export default FooterBottomContent;
