import { gsap } from "gsap";
import { useEffect } from "react";

export function useDataWIdAnimation151() {
  useEffect(() => {
    gsap.set('[data-w-id="cd60d18e-b4b8-7c71-843f-64311e813cfc"]', {"y": "10%"});
    gsap.set('[data-w-id="cd60d18e-b4b8-7c71-843f-64311e813cfc"]', {"opacity": 0});
    const tl = gsap.timeline({"scrollTrigger": {"trigger": "[data-w-id=\"cd60d18e-b4b8-7c71-843f-64311e813cfc\"]", "start": "top bottom", "end": "bottom top", "toggleActions": "play none none none", "once": true}});
    tl.addLabel("label0");
    tl.to('[data-w-id="cd60d18e-b4b8-7c71-843f-64311e813cfc"]', {"duration": 0.5, "delay": 0.4, "ease": "power1.inOut", "y": "0%"}, "label0");
    tl.to('[data-w-id="cd60d18e-b4b8-7c71-843f-64311e813cfc"]', {"duration": 0.5, "delay": 0.4, "ease": "power1.inOut", "opacity": 1}, "label0");
  }, []);
}