import { useEffect, useRef } from "react";
import { gsap } from "gsap";

export const useBackgroundTextAnimation = () => {
  const backgroundTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (backgroundTextRef.current) {
      gsap.fromTo(
        backgroundTextRef.current,
        { y: 0 },
        { y: -50, duration: 1, ease: "power2.out" },
      );
    }
  }, []);

  return backgroundTextRef;
};
